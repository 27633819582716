.accordion-header .badge {
    margin-left: 8px;
}

.add-course-row {
    height: 100%;
}

.additional-details {
    margin-top: 16px;
    margin-bottom: 16px;
}

.additional-details .row {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 8px;
}

.additional-details .row:nth-child(1) {
    margin-top: 0px;
}

.additional-details .accordion-header .accordion-button .col:nth-child(1) {
    margin-right: 16px;
    font-size: 16pt;
}

.additional-details .accordion-header .accordion-button svg {
    vertical-align: middle;
    margin-top: -0.2em;
}

.application-program-selection-text {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
}

button.add-course {
    width: 100%;
}

button.add-degree {
    display: inline-block;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 8px;
}

.card-header {
    background-color: transparent !important;
}

.contact-email {
    width: auto !important;
    display: inline;
    background-color: transparent;
    cursor: pointer !important;
    padding-left: 0px;
    padding-right: 0px;
}

.course {
    background-color: var(--bs-primary-bg-subtle);
}

.course-code {
    font-size: 8pt;
    overflow: hidden;
    height: 1lh;
    margin-top: -12px;
    color: var(--bs-secondary-color);
}

.course-degree {
    font-size: 8pt;
    overflow: hidden;
    margin-top: -12px;
    color: var(--bs-secondary-color);
    white-space: nowrap;
}

.course-col {
    padding: 4px;
    display: block;
    float: left;
}

.course-comment {
    overflow-wrap: anywhere;
    height: 3lh;
    overflow: hidden;
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.course-comment~.row .col:nth-child(1) p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.course-title {
    overflow: hidden;
    white-space: nowrap;
    padding-top: 4px;
    text-overflow: ellipsis;
}

.degree-body {
    padding: 4px;
}

.degree-courses {
    padding: 0px;
    margin: 0px;
}

.delete-degree {
    margin-right: 20px;
}

.degree-title {
    overflow-wrap: anywhere;
    max-width: calc(100% - 100px);
    max-height: 2lh;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.export-button {
    width: 100%;
}

.export-comment {
    margin-bottom: 16px;
}

.field-description {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: var(--bs-secondary);
}

.field-description .col {
    padding-left: 0px;
    padding-right: 0px;
}

.field-description .col:nth-child(1) svg {
    margin-top: -0.2em;
}

.field-description .col:nth-child(2) {
    padding-left: 8px;
}

form.student {
    margin-top: 24px;
}

.form-label.mandatory:after {
    content: "*";
    color: var(--bs-form-invalid-border-color);
    margin-left: 4px;
    display: inline-block;
}

form div .row {
    margin-top: 16px;
}

form div:nth-child(1) .row {
    margin-top: 0px;
}

.modal-form>div {
    margin-bottom: 16px;
}

.modal-form .row {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-form .col>div {
    margin-bottom: 16px;
}

.modal-form .col>.dropdown {
    margin-bottom: 0px;
}

.modal-form .dropdown.month-picker {
    width: 100%;
}

.modal-form .dropdown.month-picker button {
    width: 100%;
    text-align: left;
}

.modal-form .dropdown.month-picker .dropdown-toggle:after {
    vertical-align: auto;
    margin-top: calc(0.5lh - 0.15em);
    float: right;
}

@media (min-width: 576px) {
    .degree-modal>.modal-dialog {
        max-width: 100%;
        width: auto !important;
        margin: var(--bs-modal-margin);
    }
}

@media (min-width: 992px) {
    .degree-modal>.modal-dialog {
        max-width: calc(992px - 76px);
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

.nav-pills .nav-link.credits.active {
    background-color: var(--bs-primary);
}

.nav-pills .nav-link.prerequisites.active {
    background-color: var(--bs-success);
}

.nav-pills .nav-link.export.active {
    background-color: var(--bs-warning);
    color: var(--bs-gray-900);
}

.nav {
    padding-left: 0px;
    padding-right: 0px;
}

.prerequisite-body {
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.prerequisite-course {
    background-color: var(--bs-success-bg-subtle);
}

.prerequisite-course .card-header .btn {
    visibility: hidden;
}

.prerequisite-course-category-title {
    overflow-wrap: anywhere;
    max-height: 2lh;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.prerequisite-courses {
    margin: 0px;
    border-bottom: 1px solid var(--bs-border-color-translucent);
    margin-left: -4px;
    margin-right: -4px;
    padding: 4px;
}

.prerequisite-courses:last-child {
    border-bottom: none;
}

.prerequisite-courses>div {
    padding: 0px;
}

.prerequisite-courses .col.prerequisite {
    padding: 4px !important;
}

.process-tabs {
    padding-left: 0px;
    padding-right: 0px;
}

.process-tabs .tab-pane {
    padding-top: 20px;
}

.react-select {
    margin-top: 0px !important;
}

.react-select svg {
    fill: inherit;
}

.row.logo-header {
    border-bottom: 1px solid #00c1d4;
    padding-top: 24px;
    padding-bottom: 8px;
    margin-bottom: 20px;
}

.row.footer {
    border-top: 1px solid #00c1d4;
    padding-top: 4px;
    padding-bottom: 4px;
    color: var(--bs-secondary-color)
}

.row.main-text-body {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
}

.row.main-text-body.proceeding {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 8px;
}

.row.student {
    margin-top: 16px;
}

.row.student~div.row {
    padding-left: 12px;
    padding-right: 12px;
    margin-top: 24px;
}

.row.student~div.row~div.row {
    margin-top: 24px;
}

@media (max-width: 575px) {
    .start-over .col:nth-child(1) {
        margin-bottom: 8px;
    }
}

.student-details-title {
    overflow-wrap: anywhere;
    max-height: 2lh;
    overflow-y: hidden;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.student-match {
    background-color: var(--bs-primary-bg-subtle);
}

.student-match .row .card-text {
    white-space: nowrap;
}

.student-match .credits-description {
    margin-top: 16px;
    font-size: 8pt;
}

.student-match form {
    margin-top: 16px;
}

.student-match form .plus-minus {
    width: 38px;
    background-color: var(--bs-success);
    color: white;
}

.student-match form .credits {
    text-align: center;
}

.student-match-col {
    padding: 4px;
    display: block;
    float: left;
}

h1.top-header {
    width: auto;
    margin-top: 8px;
}

.tab-pane {
    padding-bottom: 24px;
}

/* https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
.credits input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.credits input[type=number] {
    -moz-appearance: textfield;
}